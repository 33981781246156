/* eslint-disable no-param-reassign */
import { UIButton, UIFormattedNumber, UILayout, UIText } from 'bora-material-ui'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { showModal } from '../../../../actions'
import MaritimeOptionContainer from '../../../Booking/containers/MaritimeOptions/MaritimeOption.connect'
import { notEmpty } from '../../../../utils/ramda-extend'
import SelectedTicketsMapper from '../../../Booking/forms/TicketsForm/components/SelectedTicketsMapper'
import Loadable from 'react-loadable'
import { getAttributeFromRoute, getSelectedTicketsValues } from '../../../../services/pricing/reducers/selectors'
import {
  getCurrentReservationOrNull,
  getErrorDetails,
  getShouldForbidClosingModals,
} from '../../../../services/reservation/selectors'
import {
  allPropsFilled,
  getGuestSeqNumsWithModifiedData,
  makeIndividualLabels,
} from '../../../Booking/forms/CustomerInfo/PassengersHeap.util'
import { path } from 'ramda'
import { getFormValues } from 'redux-form'
import {
  getSelectedRoute,
  getSelectedSailPackages,
  getTempPassengerData,
  isRoundTrip as getIsRoundTrip,
} from '../../../../services/user-selections'
import { getCitizenshipList } from '../../../../services/user/selectors'
import { IncDecLoading } from '../../../Booking/containers/MainInside/Loaders'
import { calculateTotalPriceWithoutTaxes, normalizePrices } from '../../../../utils/priceUtils'
import { selectors } from '../../../Booking/provider'
import messages from '../../../../consts/messages'
import moduleStyles from '../../Modals.module.scss'
import { translate } from '../../../Common/Translator'
import { setShouldForbidClosingModals } from '../../../../services/reservation/actions/reservation'
import { getAllSailPackages } from '../../../../services/routes/sailPackages/reducers'
import { ModalError } from '../ModalError'
import { getErrorCodeFromProps } from '../AddOrEditVehicleModal/AddOrEditVehicleModalContent'

// const LoadableCustomerForm = Loadable({
//   loader: () => import('../../../Booking/forms/CustomerInfo'),
//   loading: () => null,
// })

export const HIDE_FORM_DETAILS_ATTRIBUTE = 'HIDE_PASSENGER_DATA_FORM'

const LoadableTicketsInfoForm = Loadable({
  loader: () => import('../../../Booking/forms/CustomerInfo/PassengersInfo'),
  loading: () => null,
})

class AddOrEditPassengerTicketsModalContent extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      isAddon,
      shouldForbidClosingModals,
      selectedAddonTickets,
      selectedTicketsList,
      ticketsType,
      showModal,
      setShouldForbidClosingModals,
      errorCode = '',
    } = this.props
    const {
      isAddon: prevIsAddon,
      selectedAddonTickets: prevSelectedAddonTickets,
      selectedTicketsList: prevSelectedTicketsList,
      ticketsType: prevTicketsType,
    } = prevProps

    let selectedTicketsForMapper = isAddon ? selectedAddonTickets : selectedTicketsList || []
    selectedTicketsForMapper = selectedTicketsForMapper.filter((ticket) => ticket.code === ticketsType)

    let prevSelectedTicketsForMapper = prevIsAddon ? prevSelectedAddonTickets : prevSelectedTicketsList || []
    prevSelectedTicketsForMapper = prevSelectedTicketsForMapper.filter((ticket) => ticket.titles.en === prevTicketsType)

    if (
      !errorCode &&
      !selectedTicketsForMapper.length &&
      prevSelectedTicketsForMapper.length > selectedTicketsForMapper.length
    ) {
      showModal('')
    }

    if (notEmpty(selectedTicketsForMapper) && shouldForbidClosingModals) {
      setShouldForbidClosingModals(false)
    }
  }

  render() {
    const {
      showModal,
      editMode,
      selectedTicketsList,
      selectedAddonTickets,
      fetchingModify,
      changeItemQtty,
      maxValues,
      ticketsCount,
      isAddon,
      ticketsType,
      arePassengerFormsValid,
      prices,
      isTaxi,
      errorCode,
      doublePrice,
      hidePassengerDetails,
    } = this.props

    let selectedTicketsForMapper = isAddon ? selectedAddonTickets : selectedTicketsList
    selectedTicketsForMapper = selectedTicketsForMapper.filter((ticket) => ticket.code === ticketsType)
    const currency = selectedTicketsForMapper && selectedTicketsForMapper[0] && selectedTicketsForMapper[0].currency
    const subTotal = calculateTotalPriceWithoutTaxes(selectedTicketsForMapper, prices)
    const isSeniorCitizen = ticketsType === 'PAX003'

    return (
      <UILayout width="500px" data-testid="Modal-Passenger-Details" column bgColor="white" xs={{ width: '100%' }}>
        <UILayout padding="20px 0" borderBottom="1px solid #C1CADE" center className={moduleStyles.direction}>
          <UIText
            font="ADPortsGroupBold, sans-serif"
            color="#2D3955"
            size="18px"
            className={moduleStyles.text}
            translate={messages.addOrEditPassengerModalTitle}
          />
        </UILayout>

        <ModalError errorCode={errorCode} />

        <UILayout
          display-if={isSeniorCitizen}
          bgColor="#FFF7E3"
          padding="10px"
          border="1px solid #A67400"
          borderRadius="6px"
        >
          <UIText
            size="16px"
            color="#593E00"
            formattedMessage={translate(messages.informationalTextAboutSeniorCitizen)}
          />
        </UILayout>

        <UILayout column padding="20px 0" borderBottom="1px solid #C1CADE">
          <IncDecLoading display-if={!notEmpty(selectedTicketsForMapper)} />
          <SelectedTicketsMapper
            disabled={(fetchingModify && !editMode) || isAddon}
            tickets={selectedTicketsForMapper}
            changeItemQtty={changeItemQtty}
            maxValues={maxValues}
            display-if={notEmpty(selectedTicketsList) || notEmpty(selectedAddonTickets)}
            editMode={editMode}
            ticketsCount={ticketsCount}
          />
        </UILayout>

        <UILayout column borderBottom="1px solid #C1CADE" display-if={!(isTaxi || hidePassengerDetails)}>
          {/* <LoadableCustomerForm /> */}
          <LoadableTicketsInfoForm
            changeItemQtty={changeItemQtty}
            selectedTicket={selectedTicketsForMapper[0]}
            ticketsType={ticketsType}
          />
        </UILayout>

        <UILayout center j-flex-center padding="20px 0" borderBottom="1px solid #C1CADE">
          <UIText
            margin="0 10px 0 0"
            font="ADPortsGroupBold, sans-serif"
            color="#2D3955"
            size="16px"
            translate={messages.modalSubtotal}
          />
          <UIText font="ADPortsGroupBold, sans-serif" color="#2D3955" size="16px">
            <UIFormattedNumber style="currency" currency={currency} value={doublePrice ? subTotal * 2 : subTotal} />
          </UIText>
        </UILayout>

        <UILayout j-flex-center padding="20px 0">
          <UIButton
            disabled={
              !notEmpty(selectedTicketsForMapper) ||
              fetchingModify ||
              (!arePassengerFormsValid && !(isTaxi || hidePassengerDetails))
            }
            onClick={() => showModal('')}
            bgColor="#13C5BD"
            borderRadius="6px"
            width="200px"
            height="50px"
          >
            <UILayout center j-flex-center height="100%">
              <UIText
                size="20px"
                font="ADPortsGroupBold, sans-serif"
                textTransform="uppercase"
                color="white"
                translate={messages.doneButtonLabel}
              />
            </UILayout>
          </UIButton>
        </UILayout>
      </UILayout>
    )
  }
}

const mapStateToProps = (state, { ticketsType }) => {
  const reservation = getCurrentReservationOrNull(state) || {}
  const labels = makeIndividualLabels(reservation.items, reservation.guests)
  const ticketsFormValues = getSelectedTicketsValues(state)
  const passengerTickets = Object.keys(ticketsFormValues)
    .filter((key) => ticketsFormValues[key].inventoryClass === 'DECK')
    .map((key) => ticketsFormValues[key])
    .filter((ticket) => ticket.code === ticketsType && ticket.code !== 'CHARTER')
  const passengerTicketsTitles = passengerTickets.map((ticket) => Object.values(ticket.titles))
  const filteredLabels = labels.filter((label) =>
    passengerTicketsTitles.some((coupleOfTitles) => coupleOfTitles.includes(label.type))
  )
  const citizenshipList = getCitizenshipList(state)
  const tempPassengerData = getTempPassengerData(state)
  const formValues = getFormValues('bookingForm')(state)
  const currentFormDataPassenger = path(['passengers', 0])(formValues)
  const seqNumsWithModifiedData = getGuestSeqNumsWithModifiedData(
    tempPassengerData,
    reservation.guests,
    currentFormDataPassenger && allPropsFilled(currentFormDataPassenger) ? currentFormDataPassenger : undefined,
    citizenshipList
  )
  const arePassengerFormsValid = filteredLabels.every(
    ({ seqN, ...guest }) => allPropsFilled(guest) && !seqNumsWithModifiedData[seqN]
  )
  const prices = normalizePrices(selectors.getPrices(state))

  const allSailPackages = getAllSailPackages(state)
  const selectedSailPackages = getSelectedSailPackages(state)
  const [sailPackage = {}] = selectedSailPackages
  const { code } = sailPackage
  const currentSailPackage = allSailPackages[code]
  const { taxi } = currentSailPackage
  const isTaxi = Boolean(taxi)

  const shouldForbidClosingModals = getShouldForbidClosingModals(state)
  const errorDetails = getErrorDetails(state)

  const selectedRoute = getSelectedRoute(state)
  const isRoundTrip = getIsRoundTrip(state)
  const displayRoundTripPricesAttributeValue = getAttributeFromRoute(selectedRoute, 'DISPLAY_ROUND_TRIP_PRICES')(state)
  const doublePrice = displayRoundTripPricesAttributeValue && isRoundTrip

  const hidePassengerDetails = getAttributeFromRoute(selectedRoute, HIDE_FORM_DETAILS_ATTRIBUTE)(state)

  return {
    prices,
    arePassengerFormsValid,
    isTaxi,
    shouldForbidClosingModals,
    doublePrice,
    errorCode: getErrorCodeFromProps(errorDetails),
    hidePassengerDetails,
  }
}

export default compose(
  connect(mapStateToProps, { showModal, setShouldForbidClosingModals }),
  MaritimeOptionContainer
)(AddOrEditPassengerTicketsModalContent)
