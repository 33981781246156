export const getAllMembersExceptAlreadySelectedForThisGroup = (allMembers, selectedMembers) => {
  const selectedMembersIds = selectedMembers.map(({ customerId }) => customerId)
  return allMembers.filter(({ customerId }) => !selectedMembersIds.includes(customerId))
}

export const getMembersWithoutRemovedMember = (members, customerId) => {
  return members.filter((member) => member.customerId !== customerId)
}

export const getCustomerIdsFromMembers = (members) => {
  return members.map(({ customerId }) => customerId)
}
