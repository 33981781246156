import { UILayout, UIText } from 'bora-material-ui'
import React from 'react'
import messages from '../../../../consts/messages'

export const ItemTemplate = (props) => {
  const { value: { fullName } = {} } = props || {}
  return (
    <UILayout center>
      <UIText size="16px" color="black" text={fullName} />
    </UILayout>
  )
}

export const SelectedItemTemplate = () => {
  return (
    <UILayout center margin="10px 0 0 0">
      <UIText size="16px" color="black" translate={messages.selectPassengersForCurrentGroupPlaceholder} />
    </UILayout>
  )
}
