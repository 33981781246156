import { UIButton, UIIcon, UILayout } from 'bora-material-ui'
import React from 'react'
import { connect } from 'react-redux'
import { getLocale } from '../../../../services/user-selections'
import SliderImageCard from './SliderImageCard'

class ImageSlider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentPosition: 0,
      forbidSlideAction: false,
    }

    this.ref = React.createRef()
    this.slideLeft = this.slideNext(false).bind(this)
    this.slideRight = this.slideNext(true).bind(this)
  }

  slideNext(forward = true) {
    return () => {
      const isArabic = this.props.locale === 'ar'

      if (this.state.forbidSlideAction) {
        return
      }

      this.setState({ forbidSlideAction: true })
      setTimeout(() => {
        this.setState({ forbidSlideAction: false })
      }, 350)

      if (this.state.currentPosition === 0) {
        if (!forward) {
          return
        }
      }

      if (this.props.imageLinks.length - this.state.currentPosition <= 1) {
        if (forward) {
          return
        }
      }
      this.setState((state) => ({
        currentPosition: state.currentPosition + (forward ? 1 : -1),
      }))
      this.ref.current.childNodes.forEach((childNode) => {
        childNode.style.transform = `translateX(${isArabic ? '' : '-'}${
          100 * (this.state.currentPosition + (forward ? 1 : -1))
        }%)`
      })
    }
  }

  render() {
    const { imageLinks, locale } = this.props
    const isArabic = locale === 'ar'

    this.styles = {
      button: {
        fill: '#9DA7BF',
        background: 'transparent',
        type: 'circle',
        width: '40px',
        height: '40px',
        boxShadow: 'none',
      },
      icon: {
        width: '40px',
        height: '40px',
        type: 'iconChevron',
      },
      container: {
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: isArabic ? 'row-reverse' : 'row',
        margin: '0 20px',
        direction: 'ltr',
      },
    }

    return (
      <UILayout>
        <UILayout width="auto" center>
          <UIButton {...this.styles.button} onClick={this.slideLeft}>
            <UIIcon
              {...this.styles.icon}
              style={{
                transform: this.props.locale === 'ar' ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
            />
          </UIButton>
        </UILayout>

        <div style={this.styles.container} ref={this.ref}>
          {imageLinks.map((imageLink) => {
            return <SliderImageCard key={imageLink} imageLink={imageLink} />
          })}
        </div>

        <UILayout width="auto" center>
          <UIButton {...this.styles.button} onClick={this.slideRight}>
            <UIIcon
              {...this.styles.icon}
              style={{
                transform: this.props.locale === 'ar' ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </UIButton>
        </UILayout>
      </UILayout>
    )
  }
}

export default connect((state) => {
  return {
    locale: getLocale(state),
  }
}, null)(ImageSlider)
