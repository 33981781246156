import { UILayout, UIText } from 'bora-material-ui'
import React from 'react'
import { connect } from 'react-redux'
import messages from '../../../consts/messages'
import UIElements from '../../../consts/UIElements'
import { getToken } from '../../../services/auth/reducers/selectors'
import { customerSelector } from '../../../services/customer/selectors'
import { getLocale } from '../../../services/user-selections'
import { translate } from '../Translator'

class WelcomeRowForAuthorizedUser extends React.Component {
  render() {
    const { isLogged, fullName, shouldShowName, locale } = this.props
    const isArabic = locale === 'ar'

    const styles = {
      text: {
        font: 'ADPortsGroupBold, sans-serif',
        color: '#13c5bd',
        size: '12px',
      },
    }

    if (!isLogged) {
      return null
    }

    return (
      <UILayout
        className="non-printable"
        j-flex-center
        bgColor="white"
        zIndex="10"
        margin="-10px 0 0 0"
        sm={{ margin: '0', zIndex: '9' }}
      >
        <UILayout
          j-flex-end={!isArabic}
          j-flex-start={isArabic}
          width={UIElements.APP_WIDTH}
          padding={isArabic ? '0 0 5px 0' : '0 22px 5px 22px'}
          xs={{ padding: '0 22px 5px 22px' }}
        >
          <UIText
            display-if={!shouldShowName}
            {...styles.text}
            translate={messages.welcomeTextForAuthorizedUserWithoutName}
          />
          <UIText
            display-if={shouldShowName}
            {...styles.text}
            formattedMessage={translate(messages.welcomeTextForAuthorizedUser, {
              fullName,
            })}
          />
        </UILayout>
      </UILayout>
    )
  }
}

const mapStateToProps = (state) => {
  const isLogged = Boolean(getToken(state))
  const authorizedUser = customerSelector(state)
  const { firstName = '', lastName = '' } = authorizedUser
  const fullName = `${firstName} ${lastName}`
  const shouldShowName = Boolean(firstName) || Boolean(lastName)
  const locale = getLocale(state)

  return {
    isLogged,
    fullName,
    shouldShowName,
    locale,
  }
}

export default connect(mapStateToProps, null)(WelcomeRowForAuthorizedUser)
