import React from 'react'
import PropTypes from 'prop-types'

import { UIText, UILayout, UIButton } from 'bora-material-ui'

import messages from '../../../../consts/messages'

const RoutesTabs = ({ tabs, activeTab, onClick }) => (
  <UILayout j-flex-space-between margin="0 0 30px 0" wrap>
    <UILayout width="auto">
      {tabs.map((tab) => (
        <UIButton
          color="#000"
          width="128px"
          height="48px"
          borderRadius="0px"
          click={() => onClick(tab.key)}
          background={activeTab === tab.key ? '#fff' : '#e0e0e0'}
          boxShadow={activeTab === tab.key ? '0 24px 24px 0 rgba(0, 0, 0, 0.13), 0 0 24px 0 rgba(0, 0, 0, 0.17)' : ''}
        >
          <UILayout column height="100%" bgColor="transparent" j-flex-center>
            <UIText
              color={activeTab === tab.key ? '#000000' : '#808080'}
              translate={messages[tab.translateKey]}
              textTransform="uppercase"
            />
          </UILayout>
        </UIButton>
      ))}
    </UILayout>
  </UILayout>
)

RoutesTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

RoutesTabs.contextTypes = {
  intl: PropTypes.any,
}

RoutesTabs.defaultProps = {
  tabs: [],
  onClick: () => {},
}

export default RoutesTabs
