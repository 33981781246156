import { UIButton, UIDropDownMenu, UILayout, UIText, UITextField } from 'bora-material-ui'
import React from 'react'
import { connect } from 'react-redux'
import messages from '../../../../consts/messages'
import { addGroup, modifyGroup } from '../../../../services/passengers-data/actions'
import { getMembers } from '../../../../services/passengers-data/selectors'
import { translate } from '../../../Common/Translator'
import { ItemTemplate, SelectedItemTemplate } from './DropDownTemplates'
import SelectedMembers from './SelectedMembers'
import { showModal } from '../../../../../src/actions'
import {
  getAllMembersExceptAlreadySelectedForThisGroup,
  getCustomerIdsFromMembers,
  getMembersWithoutRemovedMember,
} from './utils'
import { correctSymbolsRegExp } from '../../../../utils/validate'

const styles = {
  textField: { boxShadow: 'none', height: '36px', minHeight: 'auto', border: '1px solid #D4D4D4', borderRadius: '4px' },
  inputStyle: { background: 'transparent' },
}

const modalTitles = {
  edit: messages.editGroupOfPassengersModalTitle,
  create: messages.createGroupOfPassengersModalTitle,
}

class CreateOrEditPassengersGroupsModal extends React.Component {
  state = {
    groupName: this.props.data.legalName,
    groupNameError: '',
    selectedMembers: this.props.data.members,
  }

  handleDoneButtonClick = () => {
    const { data: { operation, companyId } = {} } = this.props
    const { addGroup, modifyGroup, closeModal } = this.props
    const { groupName, selectedMembers } = this.state
    const customerIds = getCustomerIdsFromMembers(selectedMembers)
    if (operation === 'create') {
      addGroup({
        type: 'FAMILY',
        legalName: groupName,
        customerIds,
      })
      closeModal()
      return
    }
    if (operation === 'edit') {
      modifyGroup({
        type: 'FAMILY',
        legalName: groupName,
        customerIds,
        companyId,
      })
      closeModal()
    }
  }

  handleGroupNameChange = (value) => {
    this.setState({
      groupName: value,
      groupNameError: '',
    })
  }

  handleGroupNameBlur = () => {
    const { groupName } = this.state
    const isGroupNameCorrect = correctSymbolsRegExp.test(groupName)
    if (!isGroupNameCorrect) {
      this.setState({
        groupNameError: translate(messages.invalidFormatText),
      })
    }
  }

  selectMember = (member) => {
    this.setState((prevState) => ({ selectedMembers: [...prevState.selectedMembers, member] }))
  }

  removeMember = (removedCustomerId) => {
    this.setState((prevState) => ({
      selectedMembers: getMembersWithoutRemovedMember(prevState.selectedMembers, removedCustomerId),
    }))
  }

  render() {
    const { allMembers, data } = this.props
    const { operation } = data
    const { groupName, groupNameError, selectedMembers } = this.state
    const currentTitleForModal = modalTitles[operation]
    const allMembersExceptAlreadySelectedForThisGroup = getAllMembersExceptAlreadySelectedForThisGroup(
      allMembers,
      selectedMembers
    )
    const isGroupNameCorrect = correctSymbolsRegExp.test(groupName)
    const shouldDisableDoneButton = !isGroupNameCorrect

    return (
      <UILayout column width="500px" padding="0 10px 30px 10px" bgColor="white" xs={{ width: '100%' }}>
        <UILayout borderBottom="1px solid #C1CADE" padding="0 0 20px 0">
          <UIText font="ADPortsGroupBold, sans-serif" size="18px" color="#2D3955" translate={currentTitleForModal} />
        </UILayout>

        <UILayout column>
          <UILayout margin="20px 0 20px 0">
            <UILayout width="50%" center>
              <UIText size="14px" color="#000000" translate={messages.nameOfGrouplabel} />
            </UILayout>
            <UILayout width="50%" position="relative">
              <UITextField
                id="nameOfGroup"
                value={groupName}
                onBlur={this.handleGroupNameBlur}
                change={this.handleGroupNameChange}
                hintText=""
                style={styles.textField}
                inputStyle={styles.inputStyle}
              />
              <UILayout display-if={groupNameError} j-flex-center position="absolute" top="40px">
                <UIText font="ADPortsGroupBold, sans-serif" size="10px" color="#D11800" text={groupNameError} />
              </UILayout>
            </UILayout>
          </UILayout>

          <UILayout column>
            <UILayout center margin="0 0 12px 0">
              <UIText size="14px" color="#000000" translate={messages.addPassengersToGroupLabel} />
            </UILayout>
            <UILayout>
              <UIDropDownMenu
                itemTemplate={ItemTemplate}
                selectionRenderer={SelectedItemTemplate}
                itemsArr={allMembersExceptAlreadySelectedForThisGroup}
                select={this.selectMember}
                inputStyles={styles.textField}
              />
            </UILayout>
          </UILayout>

          <SelectedMembers selectedMembers={selectedMembers} removeMember={this.removeMember} />

          <UILayout center j-flex-center margin="30px 0 0 0">
            <UIButton
              click={this.handleDoneButtonClick}
              disabled={shouldDisableDoneButton}
              height="45px"
              width="160px"
              borderRadius="6px"
              data-testid="passenger-group-CE-button"
              aria-label="button-done"
            >
              <UILayout j-flex-center center height="100%">
                <UIText
                  font="ADPortsGroupBold, sans-serif"
                  color="white"
                  size="20px"
                  textTransform="uppercase"
                  translate={messages.doneButtonLabel}
                />
              </UILayout>
            </UIButton>
          </UILayout>
        </UILayout>
      </UILayout>
    )
  }
}

const mapStateToProps = (state) => {
  const allMembers = getMembers(state)
  return {
    allMembers,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addGroup: (groupData) => dispatch(addGroup(groupData)),
    modifyGroup: (groupData) => dispatch(modifyGroup(groupData)),
    closeModal: () => dispatch(showModal('')),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrEditPassengersGroupsModal)
