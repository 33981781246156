import { UIButton, UILayout, UIText } from 'bora-material-ui'
import React from 'react'
import { connect } from 'react-redux'
import messages from '../../../consts/messages'
import UIElements from '../../../consts/UIElements'
import { changeLocale, getLocale, getMobileMenuStatus } from '../../../services/user-selections'
import { menuItemsSelector } from '../../../services/sales-static/selectors'
import { goToPage, showModal } from '../../../actions'
import { isMobile } from './AppBar'
import cn from 'classnames'
import s from './NavigationBar.module.scss'

export const styles = {
  linkContainer: {
    width: 'auto',
    padding: '0 15px',
    center: true,
    cursor: 'pointer',
  },
  linkLabel: {
    size: '12px',
    color: '#E4E8F2',
  },
}

const MaritimeNavigationBar = ({ locale, changeLocale, menuItems = [], goToPage, showModal }) => {
  const nextLocale = locale === 'en' ? 'ar' : 'en'

  const onChangeLocale = () => {
    changeLocale(nextLocale)
  }

  const firstLineItems = menuItems.filter(({ line }) => line === 1)
  const generateItemClickHandler = (link) => (e) => {
    e.preventDefault()
    goToPage(link)
  }

  return (
    <UILayout className={cn('non-printable', s.barWrap)} j-flex-center>
      <UILayout
        maxWidth={UIElements.APP_WIDTH}
        style={{ justifyContent: 'flex-end', ...(locale === 'ar' ? { flexDirection: 'row-reverse' } : {}) }}
      >
        <UILayout width="auto" className={s.itemsWrap}>
          {firstLineItems.map(({ link, id, subject, modal }) => {
            if (modal && isMobile) {
              return null
            }

            if (modal) {
              return (
                <UILayout key={id} {...styles.linkContainer}>
                  <span onClick={() => showModal(link)}>
                    <UIText {...styles.linkLabel} text={subject} />
                  </span>
                </UILayout>
              )
            }

            return (
              <UILayout key={id} {...styles.linkContainer} className={s.linkWrap}>
                <a href={link} className={s.linkElement} onClick={generateItemClickHandler(link)}>
                  <UIText {...styles.linkLabel} text={subject} />
                </a>
              </UILayout>
            )
          })}
        </UILayout>

        <UILayout width="auto">
          <UIButton background="transparent" width="40px" height="32px" onClick={onChangeLocale}>
            <UILayout height="100%" width="auto" j-flex-center center>
              <UIText size="14px" translate={messages.languagePickerMaritime} />
            </UILayout>
          </UIButton>
        </UILayout>
      </UILayout>
    </UILayout>
  )
}

export default connect(
  (state) => ({
    menuItems: menuItemsSelector(state),
    mobileMenuOpened: getMobileMenuStatus(state),
    locale: getLocale(state),
  }),
  { changeLocale, goToPage, showModal }
)(MaritimeNavigationBar)
