import { UIImage, UILayout } from 'bora-material-ui'
import React from 'react'

class SliderImageCard extends React.Component {
  render() {
    const { imageLink } = this.props

    return (
      <UILayout minWidth="100%" padding="0 10px" style={{ transition: 'transform 0.35s ease-in-out' }}>
        <UIImage width="100%" src={imageLink} style={{ borderRadius: '10px' }} />
      </UILayout>
    )
  }
}

export default SliderImageCard
