import s from './LLNavigation.module.scss'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { changeLocale, getLocale, getMobileMenuStatus, toggleMobileMenu } from '../../../../services/user-selections'
import { menuItemsSelector } from '../../../../services/sales-static/selectors'
import { goToPage } from '../../../../actions'
import { UIIcon } from 'bora-material-ui'
import { LanguagePicker } from '../../../Language/components/Selects'

export const LLNavigation = ({ menuItems, goToPage, toggleMobileMenu, mobileMenuOpened, locale, changeLocale }) => {
  return (
    <Fragment>
      <div id="secondaryMenu" className={s.secondaryMenuBarWrap} align="center">
        <ul className={s.secondaryMenuBar}>
          {menuItems.map(({ subject, link, id }) => (
            <li key={id} onClick={() => goToPage(link)}>
              {subject}
            </li>
          ))}
        </ul>
      </div>
      <div className={s.toggleIconHolder} data-testid="mobile-menu-toggle-action">
        <LanguagePicker locale={locale} display-if={mobileMenuOpened} onChange={changeLocale} />
        <div onClick={() => toggleMobileMenu()}>
          <UIIcon className={s.closeIcon} fill="#0000A0FF" display-if={mobileMenuOpened} type="iconClose" />
        </div>
        <div onClick={() => toggleMobileMenu()}>
          <UIIcon className={s.openIcon} fill="#0000A0FF" display-if={!mobileMenuOpened} type="iconMenu" />
        </div>
      </div>
      <div display-if={mobileMenuOpened} className={s.navItemsHolder} data-testid="top-menu-mobile">
        <div className={s.mobileMenuItemsUp}>
          <div>
            <UIIcon type="iconPhoneLL" margin="0 10px 0 0" />
            <span>
              <a href="tel:+37255595550">+37255595550</a>
            </span>
          </div>
          <div>
            <UIIcon type="iconFB" fill="#0000a0" margin="0 10px 0 0" />
            <span>
              <a href="https://www.facebook.com/liinilaevad" target="_blank">
                Liinilaevad
              </a>
            </span>
          </div>
        </div>
        <ul className={s.mobileMenuItems}>
          {menuItems.map(({ subject, link, id }) => (
            <li
              key={id}
              onClick={() => {
                toggleMobileMenu()
                goToPage(link)
              }}
            >
              {subject}
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  )
}

export default connect(
  (state) => ({
    locale: getLocale()(state),
    menuItems: menuItemsSelector(state),
    mobileMenuOpened: getMobileMenuStatus(state),
  }),
  { changeLocale, toggleMobileMenu, goToPage }
)(LLNavigation)
