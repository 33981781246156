import { UILayout, UIText } from 'bora-material-ui'
import React from 'react'

class PicketOption extends React.PureComponent {
  render() {
    const { groupName } = this.props

    return (
      <UILayout margin="3px" padding="2px 8px 4px 8px" width="auto" height="20px" bgColor="#FEF4B3" borderRadius="6px">
        <UIText font="ADPortsGroupBold, sans-serif" size="12px" color="#2D3955" text={groupName} />
      </UILayout>
    )
  }
}

export default PicketOption
