import { UIButton, UILayout, UIText } from 'bora-material-ui'
import React from 'react'

class MenuItem extends React.Component {
  render() {
    const { isButton = false, label, bold, locale, handleClick } = this.props
    const isArabic = locale === 'ar'

    if (isButton) {
      return (
        <UILayout j-flex-end={isArabic} padding={isArabic ? '5px 40px 5px 0' : '5px 0 5px 40px'}>
          <UIButton click={handleClick} height="auto" width="auto" background="#13C5BD" borderRadius="6px">
            <UILayout padding="7px 20px">
              <UIText font="ADPortsGroupBold, sans-serif" text={label} size="16px" />
            </UILayout>
          </UIButton>
        </UILayout>
      )
    }

    return (
      <UILayout j-flex-end={isArabic} padding={isArabic ? '7px 40px 7px 0' : '7px 0 7px 40px'}>
        <UILayout width="auto" onClick={handleClick} style={{ cursor: 'pointer' }}>
          <UIText font="ADPortsGroupBold, sans-serif" text={label} size={bold ? '20px' : '16px'} color="#2D3955" />
        </UILayout>
      </UILayout>
    )
  }
}

export default MenuItem
