import React from 'react'
import AddOrEditVehicleModalContent from './AddOrEditVehicleModalContent'

class AddOrEditVehicleModal extends React.Component {
  render() {
    return <AddOrEditVehicleModalContent />
  }
}

export default AddOrEditVehicleModal
