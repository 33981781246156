import { UIFormattedNumber, UILayout, UIText } from 'bora-material-ui'
import React from 'react'
import messages from '../../../../consts/messages'

class Subtotal extends React.PureComponent {
  render() {
    const { currency, subTotal } = this.props

    return (
      <UILayout center j-flex-center padding="20px 0" borderBottom="1px solid #C1CADE">
        <UIText
          margin="0 10px 0 0"
          font="ADPortsGroupBold, sans-serif"
          color="#2D3955"
          size="16px"
          translate={messages.modalSubtotal}
        />
        <UIText font="ADPortsGroupBold, sans-serif" color="#2D3955" size="16px">
          <UIFormattedNumber style="currency" currency={currency} value={subTotal} />
        </UIText>
      </UILayout>
    )
  }
}

export default Subtotal
