import React from 'react'
import { pathOr } from 'ramda'
import { Form, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { UILayout, UIText, UIIcon } from 'bora-material-ui'
import LabelValue from '../../Profile/components/LabelValue'
import UsersText from '../../Profile/components/Layout/UsersText'
import Content from '../../../layout/Content'
import UIElements from '../../../consts/UIElements'
import messages from '../../../consts/messages'
import { translate } from '../../Common/Translator'
import { actions, selectors } from '../../Profile/provider'
import { ActionsPanel } from '../components/ActionsPanel'
import { createCorporateAccount } from './createCorporateAccountSaga'
import { CheckAccentButton, CrossAccentButton } from '../components/buttons'
import { Alert } from '../../../components/Alert'
import { email } from '../../../utils/validate'
import { olderBrowser } from '../../../utils/browserUtils'
import { getPhoneCode } from '../../../services/utils'
// import type { FormProps } from 'redux-form'

const fieldLabelStyle = {
  align: 'left',
  padding: '0',
  size: '18px',
  whiteSpace: 'nowrap',
  width: '100px',
  xs: {
    width: 'auto',
    margin: '12px 5px 0 0',
  },
}

// type CreateCorporateAccountProps = {|
//   ...$Exact<FormProps>,
//   handleCancel: () => void,
// |}

const wrapperStyle = {
  ...(olderBrowser && { width: '1160px' }),
}

const alertStyle = {
  ...(olderBrowser && { width: '1060px' }),
}

const CreateCorporateAccountView = (props) => {
  const { handleCancel, handleSubmit, invalid, personalIdentificationNumber } = props

  return (
    <Content header={translate(messages.createCorporateAccount)}>
      <Form onSubmit={handleSubmit}>
        <UILayout
          data-testid="create-corporate-wrapper"
          column
          width="100%"
          position="relative"
          center
          id="contentWrapperLight"
          style={wrapperStyle}
        >
          <UILayout flex-end width={UIElements.CONTENT_WIDTH} maxWidth="100%" xs={{ 'j-flex-center': true }}>
            <UILayout column>
              <UILayout xs={{ column: true }} center margin="0 0 24px 0">
                <UsersText size="18px" textTransform="uppercase" translate={messages.fillInformation} />
              </UILayout>
              <UILayout xs={{ column: true }} margin="0 0 53px 0">
                <UILayout column>
                  <UILayout margin="0 0 10px 0" xs={{ column: true }}>
                    <UILayout column margin="0 24px 0 0">
                      <LabelValue
                        labelStyles={fieldLabelStyle}
                        label="firstName"
                        required
                        translateLabel={messages.firstName}
                      />
                      <LabelValue
                        labelStyles={fieldLabelStyle}
                        label="lastName"
                        required
                        translateLabel={messages.surname}
                      />
                    </UILayout>
                    <UILayout column>
                      <LabelValue
                        labelStyles={fieldLabelStyle}
                        label="phone"
                        required
                        translateLabel={messages.phone}
                      />
                      <LabelValue
                        labelStyles={fieldLabelStyle}
                        label="email"
                        required
                        translateLabel={messages.usersEmail}
                        validate={[email]}
                      />
                    </UILayout>
                  </UILayout>
                  <UILayout center>
                    <UsersText {...fieldLabelStyle} xs={{ margin: 0 }} translate={messages.idCode} />
                    <UIText margin="0 0 0 20px" size="18px" color="#000000">
                      {personalIdentificationNumber}
                    </UIText>
                    <UIIcon type="iconLock" width="14px" height="18px" margin="0 0 5px 13px" />
                  </UILayout>
                </UILayout>
              </UILayout>

              <UILayout margin="0 0 44px 0" data-testid="box-with-users-text">
                <Alert warning>
                  <UILayout style={alertStyle} column childrenStyle={{ margin: '0 0 24px 0' }}>
                    <UsersText size="20px" weight="bold" align="left" translate={messages.someImportantThings} />
                    <UsersText size="14px" formattedMessage={translate(messages.createCorporateAccountTip1)} />
                    <UsersText size="14px" formattedMessage={translate(messages.createCorporateAccountTip2)} />
                  </UILayout>
                </Alert>
              </UILayout>

              <ActionsPanel>
                <CheckAccentButton buttonType="submit" disabled={invalid} text={translate(messages.saveUser)} />

                <CrossAccentButton onClick={handleCancel} text={translate(messages.cancel)} />
              </ActionsPanel>
            </UILayout>
          </UILayout>
        </UILayout>
      </Form>
    </Content>
  )
}

function mapStateToProps(state) {
  const DEFAULT_PHONE_CODE = getPhoneCode(window.brandProps.theme)
  const customer = selectors.customerSelector(state)
  const joinToken = selectors.getJoinToken(state)

  return {
    initialValues: {
      email: customer.email,
      firstName: customer.firstName,
      code: pathOr(DEFAULT_PHONE_CODE, ['intlCode'], customer.phone),
      lastName: customer.lastName,
      personalIdentificationNumber: customer.personalIdentificationNumber,
      phoneNumber: pathOr(undefined, ['phoneNumber'], customer.phone),
      customerId: customer.customerId,
      token: joinToken,
    },
    personalIdentificationNumber: customer.personalIdentificationNumber,
  }
}

const mapDispatchToProps = {
  handleCancel: () => actions.goToPage('/'),
}

function submitHandler(values, dispatch) {
  const { code, phoneNumber, ...rest } = values

  dispatch(
    createCorporateAccount({
      phone: {
        intlCode: code,
        phoneNumber,
      },
      ...rest,
    })
  )
}

const reduxFormConfig = {
  form: 'createCorporateAccountForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
  onSubmit: submitHandler,
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm(reduxFormConfig)(CreateCorporateAccountView))
