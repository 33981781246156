import { UILayout } from 'bora-material-ui'
import React from 'react'
import { connect } from 'react-redux'
import { getUserGuideImages } from '../../../../services/sales-static/actions/userGuide'
import { getUserGuideData } from '../../../../services/sales-static/selectors'
import ImageSlider from './ImageSlider'
import { LoopCircleLoading } from 'react-loadingg'

class UserGuideModal extends React.Component {
  componentDidMount() {
    const { getUserGuideImages } = this.props
    getUserGuideImages()
  }

  render() {
    const { images, loading } = this.props

    return (
      <UILayout width="1000px" minHeight="400px" padding="5px 10px 40px 10px" column>
        {loading ? (
          <UILayout column center padding="200px 0">
            <LoopCircleLoading color={window.brandProps.muiTheme.menuItem.color} />
          </UILayout>
        ) : (
          <ImageSlider slideDuration imageLinks={images} />
        )}
      </UILayout>
    )
  }
}

const mapStateToProps = (state) => {
  const { images, loading } = getUserGuideData(state)
  return {
    images,
    loading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUserGuideImages: () => dispatch(getUserGuideImages()),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserGuideModal)
