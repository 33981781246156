import React from 'react'
import { translate } from '../../Common/Translator'
import { knownErrors } from '../../Common/known-errors'
import messages from '../../../consts/messages'
import { UILayout, UIText } from 'bora-material-ui'
import moduleStyles from './ModalError.module.scss'

export const ModalError = ({ errorCode }) => {
  return (
    <UILayout className={moduleStyles.wrapper} display-if={errorCode && knownErrors[errorCode]}>
      <UIText size="16px" color="#b42a2a" formattedMessage={translate(messages[knownErrors[errorCode]])} />
    </UILayout>
  )
}
