export const reversedDateStringToDefaultFormat = (reversedDateString = '') => {
  if (!reversedDateString) {
    return ''
  }
  const [day = '', month = '', year = ''] = reversedDateString.split('-')
  return `${year}-${month}-${day}`
}

export const checkDate = (dateString = '') => {
  const dateStringWithDefaultFormat = reversedDateStringToDefaultFormat(dateString)
  const dateRegExp = /\d{4}-\d{2}-\d{2}$/
  const isFormatCorrect = dateRegExp.test(dateStringWithDefaultFormat)
  if (isFormatCorrect) {
    const dateLowLimit = new Date('1900-01-01')
    const currentDate = new Date()
    const dateFromDateString = new Date(dateStringWithDefaultFormat)
    const isDateCorrect = dateLowLimit <= dateFromDateString && dateFromDateString <= currentDate
    return isDateCorrect
  }
  return false
}

export const getGroupNameByGroupId = (allGroups, groupId) => {
  return (allGroups.find(({ companyId: currentGroupId }) => currentGroupId === groupId) || {}).legalName
}
