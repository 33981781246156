import React from 'react'
import PropTypes from 'prop-types'

import { UIDropDownMenu, UIText } from 'bora-material-ui'

import { DefaultItemTemplate } from '../../../../formComponents'

import messages from '../../../../consts/messages'

const LanguageSelect = ({ onChange }) => (
  <UIDropDownMenu
    width="100%"
    height="74px"
    select={onChange}
    background="#efefef"
    xs={{ width: '100%' }}
    itemTemplate={DefaultItemTemplate}
    labelStyle={{ lineHeight: '74px', height: '100%', opacity: '1', top: 0 }}
    itemsArr={[
      { title: 'Estonian', key: 'et' },
      { title: 'English', key: 'en' },
      { title: 'Russian', key: 'ru' },
    ]}
    selectionRenderer={() => (
      <UIText size="12px" color="#000000" align="center" textTransform="uppercase" translate={messages.chooseAnother} />
    )}
  />
)

LanguageSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default LanguageSelect
