import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { UILayout, UIText, UIButton, UIIcon, UIFormattedNumber } from 'bora-material-ui'
import PropTypes from 'prop-types'
import { pathOr, propOr, find, propEq } from 'ramda'

import DifferenceToPay from '../components/DifferenceToPay'
import Payment from '../../Booking/containers/MainInside/Steps/ConfirmPay/Payment'
import { EditTicket } from '../../Ticket/EditTicket'

import { selectors, actions } from '../../Booking/provider'
import { CustomerInfo, TicketsInfo } from '../../Booking/containers/MainInside/Steps/ConfirmPay/ConfirmationInfo'
import { showModal, userNeedToPayAction } from '../../../actions'
import { clearExpireTimers, getReservationParts } from '../../../services/reservation/actions/reservation'

import messages from '../../../consts/messages'
import { isLaaksaareSail } from '../../../utils/laaksaare'
import s from './MyTicketEditMode.module.scss'
import { getBookingConditionsForRoute } from '../../../services/reservation/selectors'
import { translate } from '../../Common/Translator'
import { getStyle } from '../../../utils/liinilaevadThemeUtils'

export const EditModeContext = React.createContext('')

const styles = {
  buttonInner: { flexBasis: '0', justifyContent: 'space-between' },
}

const xsStyles = {
  root: { padding: '28px 15px' },
  ticketWrapper: { column: true, border: '0', padding: '0' },
  ticketInfoWrap: { padding: 0 },
  ticketEditFooter: { column: true },
  button: { width: '100%', margin: '5px 0 0 0' },
  buttonWrap: { width: '100%' },
  buttonInner: { center: true, margin: '0', width: '100%', padding: '24px' },
}

class MyTicketEditMode extends React.Component {
  componentDidMount() {
    this.props.getReservationParts({ reservationToken: this.props.editReservation.token })
    this.props.userNeedToPayAction('')
  }

  componentWillUnmount() {
    this.props.clearTimers()
  }

  render() {
    const {
      editReservation = {},
      prices,
      trips,
      firstVehicle,
      editMode,
      goBackAndCancel,
      originalReservation,
      userNeedToPayAfterEdit,
      sailPackageCode,
      outOfBookingBoundary,
    } = this.props
    const { muiTheme } = this.context
    const { items = {}, reservationOwner = {}, reservationId, token } = editReservation || {}
    const customerInfo = {
      ...reservationOwner,
      showVehicle: find(propEq('inventoryClass', 'VEHICLE'))(items),
      vehicleNumber: propOr('', 'licencePlate')(firstVehicle),
      carWidth: propOr('', 'widthInCm')(firstVehicle),
      carHeight: propOr('', 'heightInCm')(firstVehicle),
      carLength: propOr('', 'lengthInCm')(firstVehicle),
      carWeight: propOr('', 'weightInKg')(firstVehicle),
    }

    const newTotalPrice = pathOr({}, ['paymentInfo', 'totalPrice'])(editReservation)
    const oldTotalPrice = pathOr({}, ['paymentInfo', 'totalPrice'])(originalReservation)

    const amount = pathOr(0, ['paymentInfo', 'totalPrice', 'amount'], originalReservation)
    const newAmount = pathOr(0, ['paymentInfo', 'totalPrice', 'amount'], editReservation)
    const currency = pathOr(0, ['paymentInfo', 'totalPrice', 'currency'], editReservation)

    const differenceToPay = newAmount - amount
    const isLaak = isLaaksaareSail(trips) && !outOfBookingBoundary
    const tax = (window.brandProps.backendConfig && window.brandProps.backendConfig.taxAmount) || 20
    return (
      <Fragment>
        {/* TODO: Remove flex wrapper outside */}
        <div className={s.root} style={getStyle({ padding: 0, margin: 0 })}>
          <EditTicket />
        </div>

        <div style={{ height: 0, overflow: 'hidden' }}>
          <UILayout column bgColor="white" padding="14px 20px" margin="40px 0 0 0" {...muiTheme.ids.myTicket}>
            <UILayout j-flex-center>
              <UILayout padding="20px 20px" column flex-start>
                <UILayout margin="0 0 30px 0" center j-flex-center data-testid="edit-screen-title">
                  <UIText
                    color="#79909b"
                    align="center"
                    size="24px"
                    font={muiTheme.secondFontFamily}
                    translate={isLaak ? messages.editBookingNr : messages.editTicketNr}
                  />
                  <UIText
                    color="#79909b"
                    align="center"
                    size="24px"
                    margin="0 0 0 8px"
                    font={muiTheme.secondFontFamily}
                  >
                    {reservationId}
                  </UIText>
                </UILayout>

                <UILayout
                  data-testid="ticket-wrapper"
                  column
                  padding="0 20px 20px 20px"
                  border="solid 1px #bfc3c7"
                  borderRadius="4px"
                  xs={xsStyles.ticketWrapper}
                >
                  <UILayout minHeight="300px" column>
                    <EditModeContext.Provider value={editMode}>
                      <UILayout>
                        <CustomerInfo
                          {...customerInfo}
                          onEdit={(type) => this.props.showModal(type)}
                          userNeedToPayAfterEdit={userNeedToPayAfterEdit}
                        />
                      </UILayout>

                      <UILayout column xs={xsStyles.ticketInfoWrap} data-testid="ticket-info-wrap">
                        <TicketsInfo
                          trips={trips}
                          prices={prices}
                          reservation={editReservation}
                          onDelete={() => {}}
                          onEdit={(type) => this.props.showModal(type)}
                          userNeedToPayAfterEdit={userNeedToPayAfterEdit}
                        />
                      </UILayout>
                    </EditModeContext.Provider>
                    <UILayout j-flex-end display-if={userNeedToPayAfterEdit}>
                      <UILayout
                        data-testid="total-to-pay"
                        width="50%"
                        column
                        padding="24px 5px 0 24px"
                        xs={{ width: '100%' }}
                      >
                        <UILayout j-flex-space-between width="100%" margin="9px 0">
                          <UIText
                            color="#bfc3c7"
                            size="16px"
                            translate={translate(messages.subTotalWithTax, { tax })}
                          />
                          <UIText color="#000000" size="16px">
                            <UIFormattedNumber
                              value={newTotalPrice.amountWithoutTaxes}
                              style="currency"
                              currency={newTotalPrice.currency || muiTheme.defaultCurrency || 'EUR'}
                            />
                          </UIText>
                        </UILayout>
                        <UILayout j-flex-space-between width="100%" margin="9px 0">
                          <UIText color="#bfc3c7" size="16px" translate={translate(messages.tax, { tax })} />
                          <UIText color="#000000" size="16px">
                            <UIFormattedNumber
                              value={newTotalPrice.taxAmount}
                              style="currency"
                              currency={newTotalPrice.currency || muiTheme.defaultCurrency || 'EUR'}
                            />
                          </UIText>
                        </UILayout>
                        <UILayout j-flex-space-between width="100%" margin="9px 0">
                          <UIText color="#bfc3c7" size="16px" translate={messages.ticketSubtotal} />
                          <UIText color="#000000" size="16px">
                            <UIFormattedNumber
                              value={newTotalPrice.amount}
                              style="currency"
                              currency={newTotalPrice.currency || muiTheme.defaultCurrency || 'EUR'}
                            />
                          </UIText>
                        </UILayout>
                        <UILayout j-flex-space-between width="100%" margin="9px 0">
                          <UIText color="#bfc3c7" size="16px" translate={messages.paid} />
                          <UIText color="#000000" size="16px">
                            <UIFormattedNumber
                              value={oldTotalPrice.amount}
                              style="currency"
                              currency={newTotalPrice.currency || muiTheme.defaultCurrency || 'EUR'}
                            />
                          </UIText>
                        </UILayout>
                      </UILayout>
                    </UILayout>
                    {/* //NEED TO PAY */}

                    <UILayout
                      data-testid="ticket-edit-footer"
                      j-flex-space-between
                      margin="20px 0"
                      xs={xsStyles.ticketEditFooter}
                      display-if={!userNeedToPayAfterEdit}
                    >
                      <DifferenceToPay oldTotal={amount} newTotal={newAmount} currency={currency} />

                      <UILayout
                        data-testid="edit-ticket-apply-button"
                        j-flex-end
                        width="50%"
                        xs={xsStyles.buttonWrap}
                        display-if={!userNeedToPayAfterEdit}
                      >
                        <UIButton
                          id="buttonStyle"
                          click={() =>
                            this.props.finishEdit({ reservation: editReservation, reservationId, differenceToPay })
                          }
                          xs={xsStyles.button}
                          height="102px"
                          {...muiTheme.ids.buttonStyles}
                          {...muiTheme.ids.buttonActionStyles}
                          {...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.nextButton })}
                          borderRadius={
                            muiTheme.ids.buttonActionStyles &&
                            muiTheme.ids.buttonActionStyles.nextButton &&
                            muiTheme.ids.buttonActionStyles.nextButton.borderRadius
                              ? muiTheme.ids.buttonActionStyles.nextButton.borderRadius
                              : 'inherit'
                          }
                        >
                          <UILayout
                            center
                            margin="0 27px"
                            lineHeight="30px"
                            height="100%"
                            width="auto"
                            style={styles.buttonInner}
                            xs={xsStyles.buttonInner}
                          >
                            <UILayout column width="auto">
                              <UIText
                                id="buttonLabelTextStyle"
                                align="left"
                                size="18px"
                                opacity="0.5"
                                translate={messages.clickTo}
                                margin={
                                  muiTheme.ids.buttonActionStyles && muiTheme.ids.buttonActionStyles.subtitles
                                    ? muiTheme.ids.buttonActionStyles.subtitles.margin
                                    : '0'
                                }
                              />
                              <UIText
                                id="buttonTextStyle"
                                align="left"
                                size="24px"
                                textTransform="uppercase"
                                translate={differenceToPay > 0 ? messages.payTheDifference : messages.saveChanges}
                                font={
                                  muiTheme.ids.buttonActionStyles && muiTheme.ids.buttonActionStyles.titles
                                    ? muiTheme.ids.buttonActionStyles.titles.fontFamily
                                    : 'inherit'
                                }
                                {...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.titles })}
                              />
                            </UILayout>
                            <UIIcon fill="white" type="selectTicketArrow" />
                          </UILayout>
                        </UIButton>
                      </UILayout>
                    </UILayout>
                  </UILayout>
                </UILayout>

                <UILayout display-if={userNeedToPayAfterEdit} j-flex-end xs={{ width: '100%' }}>
                  <UILayout j-flex-space-between width="50%" padding="16px 20px 16px 16px">
                    <UIText color="#bfc3c7" size="20px" translate={messages.totalToPay} />
                    <UIText color="#000000" size="20px">
                      <UIFormattedNumber
                        value={differenceToPay}
                        style="currency"
                        currency={newTotalPrice.currency || muiTheme.defaultCurrency || 'EUR'}
                      />
                    </UIText>
                  </UILayout>
                </UILayout>

                <UILayout data-testid="payment-process" display-if={userNeedToPayAfterEdit}>
                  <Payment
                    onPaymentClick={this.handlePayment}
                    sailPackageCode={sailPackageCode}
                    amount={differenceToPay}
                    editMode
                  />
                </UILayout>

                <UILayout
                  data-testid="edit-mode-cancel-link"
                  center
                  j-flex-center
                  margin="24px 0"
                  onClick={() => goBackAndCancel(token)}
                >
                  <UILayout
                    data-testid="cancel-button"
                    width="auto"
                    center
                    j-flex-center
                    cursor="pointer"
                    style={{
                      ...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.backButton }),
                    }}
                  >
                    <UIIcon fill="#79909B" height="24px" width="29px" type="iconGoBack" margin="0 20px 0 0" />
                    <UIText
                      size="18px"
                      textTransform="uppercase"
                      font={muiTheme.secondFontFamily}
                      color="#79909b"
                      translate={isLaak ? messages.cancelAndBackLaak : messages.canselAndBack}
                    />
                  </UILayout>
                </UILayout>
              </UILayout>
            </UILayout>
          </UILayout>
        </div>
      </Fragment>
    )
  }

  handlePayment = (paymentCode) => {
    const { token, paymentInfo } = this.props.originalReservation
    if (paymentCode !== 'INVOICE') {
      this.props.prepareForPayment({
        reservationToken: token,
        paymentMethod: paymentCode,
      })
    } else {
      this.props.pay({
        reservationToken: token,
        paymentMethod: 'INVOICE',
        amount: paymentInfo.totalPrice.amount,
      })
    }
  }
}

MyTicketEditMode.contextTypes = {
  muiTheme: PropTypes.object,
}

MyTicketEditMode.propTypes = {
  showModal: PropTypes.func.isRequired,
  editReservation: PropTypes.any.isRequired,
  originalReservation: PropTypes.any.isRequired,
  prices: PropTypes.any.isRequired,
  trips: PropTypes.any.isRequired,
  firstVehicle: PropTypes.any.isRequired,
  editMode: PropTypes.string.isRequired,
  goBackAndCancel: PropTypes.func.isRequired,
  getReservationParts: PropTypes.func.isRequired,
  finishEdit: PropTypes.func.isRequired,
  clearTimers: PropTypes.func.isRequired,
  userNeedToPayAction: PropTypes.func.isRequired,
}

export default connect(
  (state, { paymentError }) => {
    const editMode = selectors.getEditReservationMode(state)
    const editReservation = selectors.getEditReservation(state)
    const originalReservation = pathOr('', ['reservation', 'edit', 'originalReservation'], state)

    const trips = selectors.tripsFromReservation(state)(editReservation)
    const firstTrip = pathOr({}, [[0]], trips)
    const sailPackageCode = pathOr('', ['sailRefs', [0], 'route'], firstTrip)
    const { userNeedToPayAfterEdit } = state

    return {
      trips,
      paymentError,
      editReservation,
      originalReservation,
      prices: selectors.getPrices(state),
      sailPackageCode,
      firstVehicle: selectors.getFirstVehicleFromReservation(editReservation),
      editMode,
      userNeedToPayAfterEdit,
      outOfBookingBoundary: getBookingConditionsForRoute(state),
    }
  },
  (dispatch) =>
    bindActionCreators(
      {
        showModal,
        userNeedToPayAction,
        goBackAndCancel: actions.cancelEditReservation,
        getReservationParts,
        finishEdit: actions.finishEditReservation,
        prepareForPayment: actions.prepareForPayment,
        pay: actions.pay,
        clearTimers: clearExpireTimers,
      },
      dispatch
    )
)(MyTicketEditMode)
