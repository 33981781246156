import { UILayout, UIText } from 'bora-material-ui'
import RadioButton from 'material-ui/RadioButton'
import React from 'react'
import moduleStyles from './RadioButtonGroup.module.scss'
import { CheckedRadioButton, UncheckedRadioButton } from '../../../../formComponents/OptionsRadioGroup'

class RadioButtonGroup extends React.Component {
  render() {
    const { styles, dataSource, onCheck, selectedValue } = this.props

    return (
      <UILayout height="36px" center>
        {dataSource.map(({ label, value }) => {
          return (
            <UILayout center key={value}>
              <RadioButton
                value={value}
                onCheck={onCheck}
                checked={value === selectedValue}
                checkedIcon={CheckedRadioButton}
                uncheckedIcon={UncheckedRadioButton}
                style={styles.radioButton}
              />
              <UIText translate={label} className={moduleStyles.text} />
            </UILayout>
          )
        })}
      </UILayout>
    )
  }
}

export default RadioButtonGroup
