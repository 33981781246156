import { UIIcon, UILayout } from 'bora-material-ui'
import React from 'react'

class GroupPickerIcon extends React.PureComponent {
  render() {
    const iconPosition = { right: '8px' }

    return (
      <UILayout position="absolute" top="37%" {...iconPosition} width="auto" pointerEvents="none">
        <UIIcon width="14px" type={window.brandProps.muiTheme.iconChevronDown || 'iconChevronDown'} />
      </UILayout>
    )
  }
}

export default GroupPickerIcon
