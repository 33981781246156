import { pathOr } from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import { showModal } from '../../../../actions'
import {
  inventories as getInventories,
  selectedSails as getSelectedSails,
} from '../../../../services/schedule/reducers'
import { isRoundTrip as getIsRoundTrip, changeItemQtty } from '../../../../services/user-selections'
import { selectors } from '../../../Booking/provider'
import BuyAllPassengerTicketsModalContent from './BuyAllPassengerTicketsModalContent'

class BuyAllPassengerTicketsModal extends React.Component {
  render() {
    const {
      isRoundTrip,
      thereLegTitle,
      backLegTitle,
      availableSeatsAmountThere,
      amountOfSeats,
      availableSeatsAmountBack,
      showModal,
      changeItemQtty,
    } = this.props

    return (
      <BuyAllPassengerTicketsModalContent
        icon="iconPassenger"
        changeItemQtty={changeItemQtty}
        showModal={showModal}
        isRoundTrip={isRoundTrip}
        thereLegTitle={thereLegTitle}
        backLegTitle={backLegTitle}
        availableSeatsAmountThere={availableSeatsAmountThere}
        availableSeatsAmountBack={availableSeatsAmountBack}
        amountOfSeats={amountOfSeats}
      />
    )
  }
}

const chooseFirstLegFromSailPackage = pathOr({ title: '-' }, ['legSequence', [0]])

const mapStateToProps = (state) => {
  // eslint-disable-next-line no-unused-vars
  const [_, sailDateBack] = selectors.getSailDates(state)
  const isRoundTrip = getIsRoundTrip(state)
  const selectedSailPackages = selectors.getSelectedSailPackages(state)
  const [sailPackage = {}, sailPackageBack = {}] = selectedSailPackages
  const selectedLegThere = chooseFirstLegFromSailPackage(sailPackage)
  const selectedLegBack = chooseFirstLegFromSailPackage(sailPackageBack)
  const { title: thereLegTitle } = selectedLegThere
  const { title: backLegTitle } = selectedLegBack

  const inventories = getInventories(state)
  const selectedSails = getSelectedSails(state)
  const selectedSailRefIdThere = String(selectedSails[sailPackage.code])
  const selectedSailRefIdBack = String(selectedSails[sailPackageBack.code])
  const inventoriesThere = inventories[sailPackage.code] || []
  const inventoriesBack = inventories[sailPackageBack.code] || []
  const currentSailThere = inventoriesThere.find((inventory) => inventory.sailRefId === selectedSailRefIdThere) || {}
  const currentSailBack = inventoriesBack.find((inventory) => inventory.sailRefId === selectedSailRefIdBack) || {}
  const availableInventoryClassesThere = pathOr([], ['inventories', 'availableInventoryClasses'])(currentSailThere)
  const availableInventoryClassesBack = pathOr([], ['inventories', 'availableInventoryClasses'])(currentSailBack)

  const { amount: availableSeatsAmountThere, total: amountOfSeats } =
    availableInventoryClassesThere.find((availableInventory) => availableInventory.subType === 'PASSENGER') || {}
  const { amount: availableSeatsAmountBack } =
    availableInventoryClassesBack.find((availableInventory) => availableInventory.subType === 'PASSENGER') || {}

  return {
    // ticket: ,
    isRoundTrip,
    thereLegTitle,
    backLegTitle,
    availableSeatsAmountThere,
    amountOfSeats,
    availableSeatsAmountBack,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (value) => dispatch(showModal(value)),
    changeItemQtty: (value) => dispatch(changeItemQtty(value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyAllPassengerTicketsModal)
