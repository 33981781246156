import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import 'regenerator-runtime/runtime'
import 'react-dates/initialize'
import { connect } from 'react-redux'

import { UIAppBar, UIButton, UIIcon, UIImage, UILayout } from 'bora-material-ui'

import { changeLocale, getLocale, getMobileMenuStatus, toggleMobileMenu } from '../../../services/user-selections'

import '../../../styles/main.css'
import UIElements from '../../../consts/UIElements'
import NavigationBar from './NavigationBar'
import { getMaritimeStyle, isMaritime, isBluewave } from '../../../utils/maritimeStyleUtils'
import s from './AppBar.module.scss'
import { THEME } from 'bora-material-ui/themes'
import { LanguagePicker } from '../../Language/components/Selects'
import { goToPage } from '../../../actions'
import { signout } from '../../../services/auth/actions'
import { LLNavigation } from './LLNavigation'
import WelcomeRowForAuthorizedUser from './WelcomeRowForAuthorizedUser'
import { setScreenWidth } from '../../../reducers/screenWidth'

export const { matches: isMobile } = window.matchMedia('(max-width: 600px)')
export const { matches: isTablet } = window.matchMedia('(max-width: 960px)')
export const { matches: isLaptop } = window.matchMedia('(max-width: 1025px)')
export const { matches: isSmallScreen } = window.matchMedia('(max-width: 1195px)')

const xsStyles = {
  width100: { width: '100%' },
  appBarRightIcon: {
    width: '100%',
    marginTop: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  appBarLeftIcon: { marginTop: 0, display: 'flex', alignItems: 'center' },
  appBarTitle: { flex: '1 0 auto' },
  appBar: {
    style: { width: '100%', margin: '10px 0', ...getMaritimeStyle({ position: 'absolute', top: '-10px' }, {}) },
  },
  appBarLogo: { padding: 0, textTransform: 'uppercase', borderRadius: '0px' },
}

const getStyles = (id, mobileMenuOpened) => ({
  appBar: {
    backgroundColor: UIElements.BACKGROUND_COLOR,
    boxShadow: '',
    padding: '0',
    maxWidth: '100%',
    ...id,
  },
  appBarOriental: {
    style: {
      display: mobileMenuOpened ? 'none' : 'flex',
      margin: getMaritimeStyle('20px 0 0 0'),
      ...(window.brandProps.theme !== THEME.LIINILAEVAD && { justifyContent: 'space-between' }),
    },
  },
})

const reloadWithLang =
  (locale = 'en') =>
  () => {
    const { origin } = window.location

    if (typeof locale !== 'string') {
      return window.location.assign(origin)
    }

    const url = `${origin}/#/${locale}/`
    return window.location.assign(url)
  }

class AppBar extends React.Component {
  componentDidMount() {
    const { setScreenWidth } = this.props
    window.addEventListener('resize', setScreenWidth)
    window.addEventListener('orientationchange', setScreenWidth)
  }

  componentWillUnmount() {
    const { setScreenWidth } = this.props
    window.removeEventListener('resize', setScreenWidth)
    window.removeEventListener('orientationchange', setScreenWidth)
  }

  render() {
    const { mobileMenuOpened, locale, changeLocale } = this.props
    const { muiTheme } = this.context
    const appBarStyles = getStyles(muiTheme.ids.appBar, mobileMenuOpened, locale)

    const admLogoSrc =
      locale === 'ar' ? '/images/admaritime/logo_admaritime-v2-ar.png' : '/images/admaritime/logo_admaritime-v2.png'

    return (
      <Fragment>
        <UILayout
          className="non-printable"
          flex
          j-flex-center
          width="100%"
          bgColor={UIElements.BACKGROUND_COLOR}
          zIndex={getMaritimeStyle('10', '1')}
          position={getMaritimeStyle('relative', 'static')}
          {...(muiTheme.ids.appBarOrientalWrapper || {})}
        >
          <UIAppBar
            titleStyle={xsStyles.appBarTitle}
            iconStyleLeft={xsStyles.appBarLeftIcon}
            iconStyleRight={xsStyles.appBarRightIcon}
            style={appBarStyles.appBar}
            xs={xsStyles.appBar}
            showMenuIconButton={false}
            position="static"
            elevation={0}
          >
            <UILayout
              className="non-printable"
              id="appBarOriental"
              align="center"
              style={locale === 'ar' ? { flexDirection: 'row-reverse' } : {}}
            >
              <UILayout
                display-if={!isBluewave}
                data-testid="upper-bar"
                align="center"
                ms={appBarStyles.appBarOriental}
                style={locale === 'ar' ? { flexDirection: 'row-reverse' } : {}}
              >
                <UILayout
                  padding="0px 10px"
                  width={getMaritimeStyle('auto', '100%')}
                  align="center"
                  {...muiTheme.ids.logoWrapper}
                >
                  <UIButton
                    type="icon"
                    width="auto"
                    height="auto"
                    disableRipple
                    style={xsStyles.appBarLogo}
                    click={reloadWithLang(locale)}
                  >
                    {isMaritime && <UIImage width="275px" src={admLogoSrc} />}
                    {!isMaritime && (
                      <UIIcon
                        width={UIElements.LOGO_WIDTH}
                        height={UIElements.LOGO_HEIGHT || '53px'}
                        type={`logo_${UIElements.LOGO}${locale === 'ar' ? '-ar' : ''}`}
                        fill={muiTheme.appBar.textColor}
                        {...muiTheme.ids.appLogo}
                      />
                    )}
                  </UIButton>
                </UILayout>
                {!isMobile && window.brandProps.theme === THEME.LIINILAEVAD && (
                  <Fragment>
                    <div className={s.upperBarItem}>
                      <UIIcon type="iconPhoneLL" margin="0 10px 0 0" />
                      <span>
                        <a href="tel:+37255595550">+37255595550</a>
                      </span>
                    </div>
                    <div className={s.upperBarItem}>
                      <UIIcon type="iconMailLL" margin="0 10px 0 0" />
                      <span>
                        <a href="mailto:info@liinilaevad.ee">info@liinilaevad.ee</a>
                      </span>
                    </div>
                    <div className={s.upperBarItem}>
                      <UIIcon type="iconFB" fill="#0000a0" margin="0 10px 0 0" />
                      <span>
                        <a href="https://www.facebook.com/liinilaevad" target="_blank">
                          Liinilaevad
                        </a>
                      </span>
                    </div>
                    <LanguagePicker locale={locale} onChange={changeLocale} />
                  </Fragment>
                )}
              </UILayout>
              <NavigationBar display-if={window.brandProps.theme !== THEME.LIINILAEVAD} />
            </UILayout>
          </UIAppBar>
        </UILayout>
        <WelcomeRowForAuthorizedUser display-if={isMaritime} />
        <LLNavigation display-if={muiTheme.menuRows === 2} />
      </Fragment>
    )
  }
}

AppBar.contextTypes = {
  muiTheme: PropTypes.object,
}

AppBar.propTypes = {
  locale: PropTypes.string,
  mobileMenuOpened: PropTypes.bool,
  promotions: PropTypes.array.isRequired,
}

AppBar.defaultProps = {
  locale: 'en',
  mobileMenuOpened: false,
}

export default connect(
  (state) => ({
    mobileMenuOpened: getMobileMenuStatus(state),
    locale: getLocale(state),
  }),
  { changeLocale, toggleMobileMenu, goToPage, signout, setScreenWidth }
)(AppBar)
