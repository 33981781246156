import React from 'react'
import AddOrEditTrailerModalContent from './AddOrEditTrailerModalContent'

class AddOrEditTrailerModal extends React.Component {
  render() {
    return <AddOrEditTrailerModalContent />
  }
}

export default AddOrEditTrailerModal
