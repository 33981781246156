import { UIIcon, UILayout, UIText } from 'bora-material-ui'
import React from 'react'
import messages from '../../../../consts/messages'
import { notEmpty } from '../../../../utils/ramda-extend'
import { translate } from '../../../Common/Translator'
import { defaultDataStringToReversedFormat } from '../../../Profile/containers/ProfilePassengersData/SavedPassengersDataSection/utils'

const MemberRow = ({ showFirstItemTopBorder, fullName, birthday, onRemoveClick }) => {
  const userId = fullName.replace(' ', '-').toLowerCase()

  return (
    <UILayout
      data-testid={`row-${userId}`}
      center
      borderBottom="1px solid #C1CADE"
      borderTop={showFirstItemTopBorder && '1px solid #C1CADE'}
      padding="12px 0"
    >
      <UILayout>
        <UIText
          font="ADPortsGroupBold, sans-serif"
          size="16x"
          color="#2D3955"
          text={translate(messages.memberFullnameAndBirthday, {
            fullName,
            birthday,
          })}
        />
      </UILayout>

      <UILayout center onClick={onRemoveClick} cursor="pointer" width="auto" data-testid={`remove-icon-${userId}`}>
        <UIIcon type="iconCross" fill="#D11800" />
      </UILayout>
    </UILayout>
  )
}

class SelectedMembers extends React.Component {
  render() {
    const { selectedMembers, removeMember, tickets } = this.props

    return (
      <UILayout column margin={notEmpty(selectedMembers) && !tickets && '30px 0 0 0'}>
        {selectedMembers.map(({ fullName, birthday, customerId }, id) => {
          return (
            <MemberRow
              key={customerId}
              showFirstItemTopBorder={!id && !tickets}
              fullName={fullName}
              birthday={defaultDataStringToReversedFormat(birthday)}
              onRemoveClick={() => removeMember(customerId)}
            />
          )
        })}
      </UILayout>
    )
  }
}

export default SelectedMembers
