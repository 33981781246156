import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose, ifElse, isEmpty, pathOr, reduce, T } from 'ramda'
import { injectIntl } from 'react-intl'
import { UIBottomSlider, UILayout, UIText } from 'bora-material-ui'

import { selectors } from '../../../Booking/provider'
import messages from '../../../../consts/messages'
import { showModal } from '../../../../actions'
import { editDataInReservation } from '../../../../services/reservation/actions/reservation'

import TicketsForm from '../../../Booking/forms/TicketsForm'
import { TrailerForm, VehiclesForm } from '../../../Booking/forms/VehiclesForm'

import { countResultOfChangesToSend } from './utils'
import { getReservation, selectLastEditTicketError } from '../../../Ticket/EditTicket/editTicketSelectors'
import ModalScrolledErrorText from './ModalScrolledErrorText'
import { knownErrors } from '../../../Common/known-errors'
import VehicleNotAddedWarning from '../VehicleNotAddedWarning'
import { getFormValues } from 'redux-form'
import {
  areAllFormsEmpty,
  getVehiclesFromTicketPlateNumbers,
  vehicleReadyToBeAdded,
} from '../VehicleNotAddedWarning.utils'
import { getReservationFromReserve, getVehiclePlateNumbers } from '../../../../services/reservation/selectors'
import { REMOVE_MESSAGE_ABOUT_DRIVER_TIMEOUT } from './const'

// eslint-disable-next-line no-useless-escape
const regexForErrorMessage = new RegExp(/resident\W\'([\d]+)\'/, 'i')

// eslint-disable-next-line no-unused-vars
const getIDCodeFromError = ({ message = '' } = {}) => {
  const result = regexForErrorMessage.exec(message)
  if (result && result.length) {
    // eslint-disable-next-line no-unused-vars
    const [_, code] = result
    return code || ''
  }
  return ''
}

// const withTypeDeleteAndVehicle = allPass([propEq('__type', 'deleteItems'), has('vehiclesRequests')])
// const withTypeAddAndVehicle = allPass([propEq('__type', 'addItems'), has('vehiclesRequests')])
// const isVehicleDeleteInModifications = compose(find(withTypeDeleteAndVehicle), propOr([], 'modifications'))
// const isVehicleAddInModifications = compose(find(withTypeAddAndVehicle), propOr([], 'modifications'))

const ModalEditTicketType = (props) => {
  const {
    intl,
    newData,
    reservation = {},
    vehicleIsActive,
    selectedSailPackageIndexToEdit = 0,
    trailerIsActive,
    modalError,
    formsAreEmpty,
    shouldNotShowWarning,
    isReservationForDriverFromReserve,
    isFetching = false,
  } = props
  const [, setVehicleAddingProcess] = useState([
    {
      flag: false,
      formName: 'vehiclesForm',
    },
    {
      flag: false,
      formName: 'trailersForm',
    },
  ])
  const [isDriverAddingToReservationFromReserve, setDriverAddingToReservationFromReserve] = useState(false)
  const { localIDs } = newData
  const uncheckedIDNumber =
    localIDs && localIDs.length && localIDs.some((item) => item.localID && item.status === 'not-checked')
  const isNextAvailable = formsAreEmpty || shouldNotShowWarning || !(vehicleIsActive || trailerIsActive)

  const { token, sailPackages } = reservation

  if (!sailPackages) {
    return null
  }
  if (sailPackages.length === 0) {
    throw new Error('There are no sail packages in reservation')
  }

  const {
    code: sailPackageCode,
    sailRefs: [{ sailRefId }],
    seqN,
  } = sailPackages[selectedSailPackageIndexToEdit]

  const priceCanChangeAfterApply = true

  let messageToShow
  if (modalError && modalError in knownErrors) {
    messageToShow = `${intl.formatMessage(messages[knownErrors[modalError]])}`
  } else {
    messageToShow = intl.formatMessage(messages.bookingProcessError)
  }
  const handleVehicleAddingProcess = (form) => {
    setVehicleAddingProcess((prevState) => {
      const objectToChange = prevState.find((it) => it.formName === form)
      return [prevState.find((it) => it.formName !== form), { ...objectToChange, flag: !objectToChange.flag }]
    })
    if (isReservationForDriverFromReserve) {
      setDriverAddingToReservationFromReserve(false)
    }
  }
  // const isThereVehicleAlready = items
  //   .filter((item) => item.priceCategorySubType === 'VEHICLE' && item.inventoryClass !== 'BICYCLE').length > 0
  const handleDriverFromReserveCase = () => {
    if (!isDriverAddingToReservationFromReserve) {
      setDriverAddingToReservationFromReserve(true)
      setTimeout(() => {
        setDriverAddingToReservationFromReserve(false)
      }, REMOVE_MESSAGE_ABOUT_DRIVER_TIMEOUT)
    }
  }
  return (
    <div>
      <UILayout
        data-testid="modal-edit-ticket-type"
        style={{ borderBottom: '1px solid #bfc3c7' }}
        padding="0 0 20px 0"
        margin="0 0 20px 0"
        center
        xs={{ width: '360px', margin: 'auto' }}
        column
      >
        <ModalScrolledErrorText display-if={modalError} message={messageToShow} style={{ maxWidth: '380px' }} />
        <ModalScrolledErrorText
          display-if={isDriverAddingToReservationFromReserve}
          message={intl.formatMessage(messages.driverForReserveOnlyConfirm)}
          style={{ maxWidth: '380px' }}
        />
        <TicketsForm
          editMode
          sailIndexForEdit={selectedSailPackageIndexToEdit}
          handleDriverFromReserveCase={handleDriverFromReserveCase}
        />

        <UILayout display-if={vehicleIsActive} margin="16px 0 0 0">
          <VehiclesForm editMode handleVehicleAddingProcess={handleVehicleAddingProcess} />
        </UILayout>
        <UILayout display-if={trailerIsActive} margin="16px 0 0 0">
          <TrailerForm editMode handleVehicleAddingProcess={handleVehicleAddingProcess} />
        </UILayout>
      </UILayout>
      <UILayout
        width="100%"
        data-testid="edit-ticket-type-information"
        display-if={priceCanChangeAfterApply}
        column
        j-flex-center
        height="100%"
      >
        <UIText weight="bold" color="#2c4684" translate={messages.editTicketTypePriceCanChange} />
      </UILayout>
      <VehicleNotAddedWarning
        formsAreEmpty={formsAreEmpty}
        shouldNotShowWarning={shouldNotShowWarning}
        display-if={vehicleIsActive || trailerIsActive}
      />
      <UIBottomSlider
        isEditPopup={true}
        isPopup={true}
        isNextAvailable={isNextAvailable && !uncheckedIDNumber && !isFetching}
        nextAction={() => {
          const resultOfChangesToSend = countResultOfChangesToSend({
            newData,
            editReservation: reservation,
            sailPackage: sailPackageCode,
            sailRefId,
            seqN,
          })

          props.editDataInReservation({
            reservationToken: token,
            dataToEdit: resultOfChangesToSend,
          })
        }}
        prevAction={() => props.showModal('')}
        prevTitle={intl.formatMessage(messages.cancel)}
        nextTitle={intl.formatMessage(messages.applyChanges)}
        editMode
      />
    </div>
  )
}

function mapStateToProps(state) {
  const vehiclesFormValues = getFormValues('vehiclesForm')(state)
  const trailersFormValues = getFormValues('trailersForm')(state)
  const ticketsFormValues = getFormValues('ticketsForm')(state) || { vehicleIsActive: false, trailerIsActive: false }
  const formsAreEmpty = areAllFormsEmpty([vehiclesFormValues, trailersFormValues])
  const reservationVehicles = getVehiclePlateNumbers(state)
  const temporaryAddedVehicles = getVehiclesFromTicketPlateNumbers(ticketsFormValues)
  const vehiclesReadyToBeAdded = vehicleReadyToBeAdded([
    ...(ticketsFormValues.vehicleIsActive && vehiclesFormValues),
    ...(ticketsFormValues.trailerIsActive && trailersFormValues),
  ])

  const checkIfWarningShouldNotBeShown = ifElse(
    isEmpty,
    T,
    reduce((acc, number) => reservationVehicles.includes(number) || temporaryAddedVehicles.includes(number), false)
  )

  return {
    newData: {
      ...pathOr({}, ['form', 'ticketsForm', 'values'])(state),
      ...pathOr({}, ['form', 'localTravellerIDForm', 'values'])(state),
    },
    modalError: pathOr(null, ['editTicket', 'modalError'])(state),
    lastError: selectLastEditTicketError(state),
    reservation: getReservation(state),
    vehicleIsActive: selectors.areVehiclesActive(state),
    trailerIsActive: selectors.areTrailersActive(state),
    selectedSailPackageIndexToEdit: state.selectedSailPackageIndexToEdit,
    formsAreEmpty,
    shouldNotShowWarning: checkIfWarningShouldNotBeShown(vehiclesReadyToBeAdded),
    isReservationForDriverFromReserve: getReservationFromReserve(state),
    isFetching: state.fetching,
  }
}

const mapDispatchToProps = {
  showModal,
  editDataInReservation,
}

export default compose(injectIntl, connect(mapStateToProps, mapDispatchToProps), React.memo)(ModalEditTicketType)
