import React from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIButton, UIText } from 'bora-material-ui'

import UIElements from '../../../../consts/UIElements'
import { pathOr } from 'ramda'
import { getStyle } from '../../../../utils/liinilaevadThemeUtils'

const ModalReservation = (props) => {
  const { onClick, contextText, buttonText, buttonWidth } = props

  const bgColor = pathOr(UIElements.FONT_COLOR, [
    'brandProps',
    'muiTheme',
    'ids',
    'buttonActionStyles',
    'nextButton',
    'background',
  ])(window)

  return (
    <UILayout
      data-testid="modal-reservation-expired"
      padding={getStyle('24px', '20px 0 40px 0')}
      width={getStyle('auto', '300px')}
      column
      center
      j-flex-center
    >
      <UILayout j-flex-center padding="0 0 20px 0" width={getStyle('auto', '235px')}>
        <UIText
          translate={contextText}
          weight="bold"
          lineHeight="1.25"
          color={getStyle('#0000A0', '#263238')}
          size={getStyle('18px', '16px')}
        />
      </UILayout>
      <UILayout j-flex-center>
        <UIButton
          onClick={onClick}
          background={bgColor}
          width={buttonWidth}
          height="auto"
          borderRadius={getStyle('12px')}
        >
          <UILayout height="44px" j-flex-center center>
            <UIText
              translate={buttonText}
              size={getStyle('24px', '18px')}
              weight={getStyle('700')}
              textTransform={getStyle('uppercase')}
            />
          </UILayout>
        </UIButton>
      </UILayout>
    </UILayout>
  )
}

ModalReservation.propTypes = {
  onClick: PropTypes.func.isRequired,
  contextText: PropTypes.any.isRequired,
  buttonText: PropTypes.any.isRequired,
  buttonWidth: PropTypes.string.isRequired,
}

export default ModalReservation
