import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { UILayout, UIBottomSlider, UIDropDownMenu, UIText } from 'bora-material-ui'
import messages from '../../../../consts/messages'

import AvailableTickets from '../../../Booking/containers/AvailableTickets/AvailableTickets.js'
import DepartureForm from '../../../Booking/forms/DepartureForm'
import ModalScrolledErrorText from './ModalScrolledErrorText'
import { knownErrors } from '../../../Common/known-errors'
import s from './ModalEditDateTime.module.scss'
import { getStyle } from '../../../../utils/liinilaevadThemeUtils'

const styles = {
  modalContent: { borderBottom: '1px solid #bfc3c7' },
  availableTickets: { padding: '0' },
  xs: {
    modalContent: { width: '100%' },
  },
}

const availableReplacementLegsTemplate = (props) =>
  !props ? null : (
    <UILayout height="44px" align="center">
      <UIText size="15px" color="#000000" text={props.title || props.value.title} />
    </UILayout>
  )

const selectMessageFor = (error) => {
  if (error in knownErrors) {
    if (error === 'EXCLUDED_PRICE_CATEGORY') {
      return messages.excludedPriceCategoryEditSail
    }

    return messages[knownErrors[error]]
  }
  return messages[error] || messages.bookingProcessError
}

const ModalEditDateTime = (
  {
    sailPackage,
    isNextAvailable,
    selectedReplacementLeg,
    availableReplacementLegs,
    sailDate,
    modalError,
    selectLeg,
    selectDepartureDate,
    applyChanges,
    closeModal,
    isRequestingDates = false,
    capacityError = false,
    inventoryTaken = {},
    isFetching = false,
  },
  { intl: { formatMessage } }
) => {
  const [isCancelled, setCancelled] = useState(false)
  return (
    <div className={s.root}>
      <UILayout
        data-testid="modal-edit-date-time"
        style={styles.modalContent}
        padding="20px 0"
        width={getStyle('100%', '500px')}
        xs={styles.xs.modalContent}
        column
        center
      >
        <ModalScrolledErrorText display-if={modalError} message={formatMessage(selectMessageFor(modalError))} />
        <UILayout margin="0 0 30px 0">
          <UIDropDownMenu
            key={availableReplacementLegs[selectedReplacementLeg]}
            display-if={availableReplacementLegs.length > 1}
            itemsArr={availableReplacementLegs}
            itemTemplate={availableReplacementLegsTemplate}
            selectionRenderer={availableReplacementLegsTemplate}
            select={selectLeg}
            initValue={selectedReplacementLeg}
            styles={styles.dropDownStyles}
          />
        </UILayout>
        <UILayout width="300px">
          <DepartureForm
            onEditMode
            name="sailDate"
            sailPackage={sailPackage}
            select={selectDepartureDate}
            sailDate={sailDate}
          />
        </UILayout>

        <UILayout margin="50px 0 0 0">
          <AvailableTickets
            sailPackage={sailPackage}
            additionalStyles={{
              ...styles.availableTickets,
              ...(isRequestingDates ? { opacity: 0.2 } : { opacity: 1 }),
            }}
            onEditMode
            inventoryTaken={inventoryTaken}
            isCancelled={isCancelled}
          />
        </UILayout>
      </UILayout>

      <UIBottomSlider
        isEditPopup={true}
        isPopup={true}
        isNextAvailable={isNextAvailable && !capacityError && !isFetching}
        nextAction={() => {
          applyChanges()
          setCancelled(false)
        }}
        prevAction={() => {
          closeModal()
          setCancelled(true)
        }}
        prevTitle={formatMessage(messages.cancel)}
        nextTitle={formatMessage(messages.applyChanges)}
        editMode
      />
    </div>
  )
}
ModalEditDateTime.propTypes = {
  sailPackage: PropTypes.object.isRequired,
  isNextAvailable: PropTypes.bool.isRequired,
  selectedReplacementLeg: PropTypes.number.isRequired,
  availableReplacementLegs: PropTypes.array.isRequired,
  sailDate: PropTypes.string.isRequired,
  modalError: PropTypes.string.isRequired,
  selectLeg: PropTypes.func.isRequired,
  selectDepartureDate: PropTypes.func.isRequired,
  applyChanges: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isRequestingDates: PropTypes.bool.isRequired,
  capacityError: PropTypes.bool.isRequired,
  inventoryTaken: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
}

ModalEditDateTime.contextTypes = {
  intl: PropTypes.any,
  muiTheme: PropTypes.object,
}

export default React.memo(ModalEditDateTime)
