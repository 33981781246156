import { UIButton, UILayout, UIText } from 'bora-material-ui'
import { isEmpty } from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import { showModal } from '../../../../actions'
import messages from '../../../../consts/messages'
import { addGroupTickets } from '../../../../services/reservation/actions/reservation'
import SelectedMembers from '../CreateOrEditPassengersGroupsModal/SelectedMembers'
import { getMembersWithoutRemovedMember } from '../CreateOrEditPassengersGroupsModal/utils'
import Subtotal from './Subtotal'
import moduleStyles from '../../Modals.module.scss'

class AddTicketsFromGroupModal extends React.Component {
  state = {
    selectedMembers: this.props.data.members,
  }

  handleDoneButtonClick = () => {
    const { selectedMembers } = this.state
    const { addGroupTickets, closeModal } = this.props
    const { selectedSailPackages, selectedSailRefIds, categoriesAvailableForSelection } = this.props.data
    addGroupTickets({
      members: selectedMembers,
      selectedSailPackages,
      selectedSailRefIds,
      categoriesAvailableForSelection,
    })
    closeModal()
  }

  removeMember = (removedCustomerId) => {
    this.setState((prevState) => ({
      selectedMembers: getMembersWithoutRemovedMember(prevState.selectedMembers, removedCustomerId),
    }))
  }

  render() {
    const { selectedMembers } = this.state
    const shouldDisableDoneButton = isEmpty(selectedMembers)
    const subTotal = selectedMembers.reduce((acc, { price }) => acc + price, 0)

    return (
      <UILayout
        data-testid="group-ticket-modal"
        column
        width="500px"
        padding="0 10px 30px 10px"
        bgColor="white"
        className={moduleStyles.formDirection}
        xs={{ width: '100%' }}
      >
        <UILayout borderBottom="1px solid #C1CADE" padding="0 0 20px 0">
          <UIText
            font="ADPortsGroupBold, sans-serif"
            size="18px"
            color="#2D3955"
            translate={messages.addingGroupTicketsToCartModalTitle}
          />
        </UILayout>

        <UILayout column>
          <SelectedMembers tickets selectedMembers={selectedMembers} removeMember={this.removeMember} />
          <Subtotal currency="AED" subTotal={subTotal} />
          <UILayout center j-flex-center margin="30px 0 0 0">
            <UIButton
              click={this.handleDoneButtonClick}
              disabled={shouldDisableDoneButton}
              height="45px"
              width="160px"
              borderRadius="6px"
            >
              <UILayout j-flex-center center height="100%">
                <UIText
                  font="ADPortsGroupBold, sans-serif"
                  color="white"
                  size="20px"
                  textTransform="uppercase"
                  translate={messages.doneButtonLabel}
                />
              </UILayout>
            </UIButton>
          </UILayout>
        </UILayout>
      </UILayout>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addGroupTickets: (config) => dispatch(addGroupTickets(config)),
    closeModal: () => dispatch(showModal('')),
  }
}

export default connect(null, mapDispatchToProps)(AddTicketsFromGroupModal)
