import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { captureException } from '@sentry/browser'

import { UILayout, UIText } from 'bora-material-ui'

import { scrollUp } from './scrollUp'

import messages from '../../consts/messages'
import { translate } from './Translator'
import { isUnrecoverableError, knownErrors } from './known-errors'
import { isMaritime } from '../../utils/maritimeStyleUtils'
import * as R from 'ramda'
import { withRouter } from 'react-router-dom'

const Warning = (props, { muiTheme, intl }) => {
  const { className, errorMessage, onlyError, style, errorDetails, paymentError } = props
  const { code, id, message = '' } = errorDetails

  const errorCodeMessage = id ? ` (${translate(messages.warningErrorCodePrefix, { id })})` : ''

  let messageToShow = onlyError && id ? errorCodeMessage : errorMessage
  if (errorMessage) {
    captureException(errorMessage)
    console.warn(errorMessage)
  }

  if (code && id) {
    captureException(messageToShow)
  }

  let withAction = false

  const matchUrl = R.pathOr('', ['match', 'url'])(props)

  if (code in knownErrors) {
    messageToShow = intl.formatMessage(messages[knownErrors[code]]) + errorCodeMessage

    if (isMaritime) {
      messageToShow = intl.formatMessage(messages[knownErrors[code]])
    }

    if (isUnrecoverableError(code)) {
      withAction = true
    }

    if (matchUrl.endsWith('/confirm') && code === 'METHOD_ARGUMENT_NOT_VALID') {
      messageToShow = intl.formatMessage(messages.customerInformationValidationError)
    }
  } else if (errorMessage === 'INVALID_NOTIFICATION_STATUS') {
    messageToShow = intl.formatMessage(messages.paymentCCInvalidStatusError)
  } else if (message && message.startsWith('The payment is already inProgress')) {
    messageToShow = intl.formatMessage(messages.paymentInProgressError)
  } else {
    messageToShow = intl.formatMessage(messages.bookingProcessError) + errorCodeMessage

    if (isMaritime && !paymentError) {
      messageToShow = intl.formatMessage(messages.bookingProcessError)
    } else {
      messageToShow = intl.formatMessage(messages.paymentInProgressError)
    }
  }

  const layoutStyle = {
    padding: '10px 20px',
    backgroundColor: muiTheme.warningBgColor,
    borderRadius: '3px',
    ...style,
  }

  return (
    <UILayout data-testid="error-wrapper" className={className} column flex-center width="auto" style={layoutStyle}>
      <UIText
        margin="0px 0px 10px 0px"
        textTransform="uppercase"
        size="18px"
        letterSpacing="0.5px"
        weight="bold"
        color={muiTheme.warningColor}
        translate={messages.warningTitle}
        font={muiTheme.secondFontFamilyDem}
      />
      {props.errorComponent ? (
        props.errorComponent
      ) : (
        <Fragment>
          <UIText
            margin="0px 0px 10px 0px"
            size="16px"
            color="#000000"
            translate={messages.paymentWarningInfoText}
            display-if={!onlyError}
          />
          <UIText display-if={!withAction} margin="0px 0px 10px 0px" size="16px" color="#000000">
            {messageToShow}
          </UIText>
          <UIText
            display-if={isMaritime && errorCodeMessage && !withAction}
            margin="0px 0px 10px 0px"
            size="16px"
            color="rgb(90, 90, 90)"
            text={errorCodeMessage}
          />
          <UIText
            display-if={withAction}
            margin="0px auto 10px auto"
            size="16px"
            color="#000000"
            align="center"
            formattedMessage={messageToShow}
            onAction={() => (location.href = window.location.origin)}
          />
          {!isMaritime && (
            <UIText
              margin="0px"
              size="16px"
              color="#000000"
              translate={messages.paymentWarningFinalText}
              display-if={!onlyError}
            />
          )}
        </Fragment>
      )}
    </UILayout>
  )
}

Warning.contextTypes = {
  muiTheme: PropTypes.object,
  intl: PropTypes.object,
}

Warning.defaultProps = {
  errorComponent: null,
  onlyError: false,
  paymentError: false,
  style: {},
  errorDetails: {},
}

Warning.propTypes = {
  className: PropTypes.string,
  errorComponent: PropTypes.any,
  onlyError: PropTypes.bool,
  paymentError: PropTypes.bool,
  style: PropTypes.any,
  errorMessage: PropTypes.string.isRequired,
  errorDetails: PropTypes.object,
}

const enhance = R.compose(scrollUp, withRouter)

export default enhance(Warning)
