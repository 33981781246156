import { UIButton, UILayout, UIText } from 'bora-material-ui'
import React from 'react'
import { connect } from 'react-redux'
import { showModal } from '../../../../actions'
import messages from '../../../../consts/messages'
import { getModifying } from '../../../../services/reservation/selectors'
import { normalizePrices } from '../../../../utils/priceUtils'
import { TrailerForm } from '../../../Booking/forms/VehiclesForm'
import { selectors } from '../../../Booking/provider'

class AddOrEditTrailerModalContent extends React.Component {
  state = {
    hasError: false,
    error: null,
    isVehicleAddingProcess: [
      {
        flag: false,
        formName: 'vehiclesForm',
      },
      {
        flag: false,
        formName: 'trailersForm',
      },
    ],
  }

  handleVehicleAddingProcess = (form) => {
    this.setState((prevState) => {
      const objectToChange = prevState.isVehicleAddingProcess.find((it) => it.formName === form)
      return {
        ...prevState,
        isVehicleAddingProcess: [
          prevState.isVehicleAddingProcess.find((it) => it.formName !== form),
          { ...objectToChange, flag: !objectToChange.flag },
        ],
      }
    })
  }

  render() {
    const { showModal, fetchingModify /* , prices */ } = this.props

    return (
      <UILayout width="500px" column data-testid="addOrEditPassengerTickets-modal" bgColor="white">
        <UILayout padding="20px 0" borderBottom="1px solid #C1CADE" center>
          <UIText
            font="ADPortsGroupBold, sans-serif"
            color="#2D3955"
            size="18px"
            translate={messages.addOrEditTrailerModalTitle}
          />
        </UILayout>

        <UILayout column padding="20px 0" borderBottom="1px solid #C1CADE">
          <TrailerForm handleVehicleAddingProcess={this.handleVehicleAddingProcess} />
        </UILayout>

        <UILayout center j-flex-center padding="20px 0" borderBottom="1px solid #C1CADE">
          <UIText
            margin="0 10px 0 0"
            font="ADPortsGroupBold, sans-serif"
            color="#2D3955"
            size="16px"
            translate={messages.modalSubtotal}
          />
          <UIText font="ADPortsGroupBold, sans-serif" color="#2D3955" size="16px">
            {/* <UIFormattedNumber style="currency" currency={currency} value={subTotal} /> */}
          </UIText>
        </UILayout>

        <UILayout j-flex-center padding="20px 0">
          <UIButton
            disabled={fetchingModify}
            onClick={() => showModal('')}
            bgColor="#13C5BD"
            borderRadius="6px"
            width="200px"
            height="50px"
          >
            <UILayout center j-flex-center height="100%">
              <UIText
                size="20px"
                font="ADPortsGroupBold, sans-serif"
                textTransform="uppercase"
                color="white"
                translate={messages.doneButtonLabel}
              />
            </UILayout>
          </UIButton>
        </UILayout>
      </UILayout>
    )
  }
}

const mapStateToProps = (state) => ({
  prices: normalizePrices(selectors.getPrices(state)),
  fetchingModify: getModifying(state),
})

export default connect(mapStateToProps, { showModal })(AddOrEditTrailerModalContent)
